.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.image-overlay {
  background-color: black;
  opacity: 80;
  color: fuchsia;
}

.image-bookmarked {
  outline: fuchsia;
  outline-width: 10px;
}

.image-notbookmarked {
  outline: none;
}

.gallery-overlay {
  z-index: 3;
  position: absolute;
}

.overlay-logo {
  position: fixed;
  width: 200px;
  left: 10px;
}

span.image-gallery-description {
  font-weight: large !important;
  font-weight: bolder;
  left: 20px;
}

.overlay-title {
  position: fixed;
  width: 200px;
  color: white;
  font-size: large;
  font-weight: bolder;
  right: 0px;
}

.tags-overlay {
  position: fixed;
  width: 20px;
  left: 60px;
  top: 65%;
  z-index: 100;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import "~react-image-gallery/styles/css/image-gallery.css";
